export const APES_DATA_LOAD_TOOL_MAIN_BUCKET = '/public'
export const WMS_BUCKET_ENTITIES = 'wmsEntities';
export const SUPPLY_CHAIN_BUCKET_ENTITIES = 'supplyChainEntities';
export const SUPPLY_CHAIN_BUCKET_DESTINATION = 'supplyChainFiles';
export const WMS_DOWNLOAD_RESOURCE_BUCKET = 'wmsCustomerDownload';
export const WMS_BUCKET_DESTINATION = 'wmsFiles';

export const PRODUCTS_BUCKET_NAME = 'productVariants';
export const PURCHASE_ORDERS_BUCKET_NAME = 'purchaseOrders';
export const TRANSFER_ORDERS_BUCKET_NAME = 'transferOrders';
export const RETURN_ORDERS_BUCKET_NAME = 'returnOrders';
export const ORDERS_BUCKET_NAME = 'orders';

export const PRODUCTS_ENTITY_NAME = 'Productos';
export const PURCHASE_ORDER_ENTITY_NAME = 'Ordenes_compra';
export const TRANSFER_ORDER_ENTITY_NAME = 'Ordenes_traslado';
export const RETURN_ORDER_ENTITY_NAME = 'Ordenes_devolucion';
export const ORDER_ENTITY_NAME = 'Pedidos';