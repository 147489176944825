import React, { useState, useEffect } from 'react';
import './App.css';
import './config.js'
import logo from './Logo.png';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { S3ProviderListOutputItem } from '@aws-amplify/storage';
import { Storage } from 'aws-amplify';
import Dropzone from 'react-dropzone';
import { Auth } from 'aws-amplify';

import {APES_DATA_LOAD_TOOL_MAIN_BUCKET, WMS_BUCKET_ENTITIES,
SUPPLY_CHAIN_BUCKET_ENTITIES,
WMS_BUCKET_DESTINATION,
SUPPLY_CHAIN_BUCKET_DESTINATION,
PRODUCTS_ENTITY_NAME,
PRODUCTS_BUCKET_NAME,
PURCHASE_ORDER_ENTITY_NAME,
PURCHASE_ORDERS_BUCKET_NAME,
TRANSFER_ORDER_ENTITY_NAME,
TRANSFER_ORDERS_BUCKET_NAME,
RETURN_ORDER_ENTITY_NAME,
RETURN_ORDERS_BUCKET_NAME,
ORDER_ENTITY_NAME,
ORDERS_BUCKET_NAME,
WMS_DOWNLOAD_RESOURCE_BUCKET} from './config'

interface ErrorModalProps {
  message: string;
  onClose: () => void;
}

interface AppState {
  activeTab: 'wmsEntities' | 'supplyChainEntities' | 'wmsCustomerDownload';
}

function App() {
  const [tabChangeCounter, setTabChangeCounter] = useState<number>(0);
  const [publicFiles, setPublicFiles] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<AppState['activeTab']>('wmsEntities');
  const [publicFileKeys, setPublicFileKeys] = useState<S3ProviderListOutputItem[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState<string | null>(null);
  const [notification, setNotification] = useState<{ type: 'success' | 'error', message: string } | null>(null);


  const handleSignOut = async () => {
    try {
      await Auth.signOut(); 
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };


  const fetchPublicFiles = async (tab: string) => {
    try {
      const { results } = await Storage.list('', { level: 'public' });
      const files = results.filter(
        (entity) =>
          entity.key &&
          !entity.key.includes('/public')
      );
      setPublicFileKeys(files);
      const s3Files = await Promise.all(
        files.map(async (entity) => await Storage.get(entity.key!, { level: 'public' }))
      );
      setPublicFiles(s3Files);

      const tableData = s3Files.map((item) => ({
        fileName: getFileNameWithoutExtension(item, tab),
        downloadLink: item,
      }));
      setTableData(tableData);
    } catch (error) {
      console.error('Error fetching public files:', error);
    }
  };

  useEffect(() => {
    fetchPublicFiles(activeTab);
    const intervalId = setInterval(() => {
      fetchPublicFiles(activeTab);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [tabChangeCounter, activeTab]);

  const handleTabChange = (tab: AppState['activeTab']) => {
    setActiveTab(tab);
    setTabChangeCounter((prevCounter) => prevCounter + 1);
  };

  const getFileNameWithoutExtension = (item: string, prefix: string) => {
    const url = new URL(item);
    const pathWithoutPrefix = url.pathname.replace(`${APES_DATA_LOAD_TOOL_MAIN_BUCKET}/${prefix}/`,'')
    return pathWithoutPrefix;
  };


  const handleFileUpload = async (files: File[], bucketPrefix: string) => {
    try {
    const allowedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
      const validFiles = files.filter((file) => allowedFileTypes.includes(file.type));
  
      if (validFiles.length === 0) {
        throw new Error('Solo se permiten archivos con extensiones xlsx y csv.');
      }
  
      const fileUploadPromises = validFiles.map((file) => {
        const filenameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
        const timestamp = new Date().getTime();
        var subBucket = '';
        
        if(activeTab === 'wmsEntities'){
          switch (filenameWithoutExtension) {
            case PRODUCTS_ENTITY_NAME:
              subBucket = PRODUCTS_BUCKET_NAME
              break;
            case PURCHASE_ORDER_ENTITY_NAME:
              subBucket = PURCHASE_ORDERS_BUCKET_NAME;
              break;
            case TRANSFER_ORDER_ENTITY_NAME:
              subBucket = TRANSFER_ORDERS_BUCKET_NAME;
              break;
            case RETURN_ORDER_ENTITY_NAME:
              subBucket = RETURN_ORDERS_BUCKET_NAME;
              break;
            case ORDER_ENTITY_NAME:
              subBucket = ORDERS_BUCKET_NAME;
              break;
  
            default:
              throw new Error(`Nombre de archivo no válido: ${file.name}`);
          }
        }
  
        if (subBucket === null) {
          throw new Error(`Nombre de archivo no válido: ${file.name}`);
        }
        const newFilename = `${filenameWithoutExtension}_${timestamp}${file.name.slice(file.name.lastIndexOf('.'))}`;
        const filePath = `${bucketPrefix}/${subBucket}/${newFilename}`;
        return Storage.put(filePath, file);
      });
  
      await Promise.all(fileUploadPromises);
      fetchPublicFiles(activeTab);
      setNotification({ type: 'success', message: 'Carga exitosa' });
   

      setTimeout(() => {
        setNotification(null);
      }, 2000);

    } catch (error: any) {
      setShowErrorModal(true);
      setNotification({ type: 'error', message: error.message });
    }
  };

  const closeErrorModal = () => {
    setNotification(null);
  };


  return (
    <Authenticator initialState='signIn' hideSignUp={true} className='authenticator'>
      <div className="App">
        <header className="App-header">
          <img src={logo} alt="Logo de la aplicación" width="200" className="App-logo" />
          <h1 className="App-title">Apes Data Load Tool</h1>
          <button className="sign-out-button" onClick={handleSignOut}>
          Cerrar sesión
        </button>
          

        </header>

        <nav className="tabs">
          <button
            className={`tab ${activeTab === 'wmsEntities' ? 'active' : ''}`}
            onClick={() => handleTabChange('wmsEntities')}
          >
            WMS
          </button>


          <button
            className={`tab ${activeTab === 'supplyChainEntities' ? 'active' : ''}`}
            onClick={() => handleTabChange('supplyChainEntities')}
          >
            Supply Chain
          </button>
          <button
            className={`tab ${activeTab === 'wmsCustomerDownload' ? 'active' : ''}`}
            onClick={() => handleTabChange('wmsCustomerDownload')}
          >
            Descargas WMS
          </button>
        </nav>

        <div className="tab-content">
          {activeTab === 'wmsEntities' && (
            <div className="columns">
              <div className="column-left">
                Entidades descargables
                <div className="file-container">
                  {publicFiles.filter(item => item.includes(`${APES_DATA_LOAD_TOOL_MAIN_BUCKET}/${WMS_BUCKET_ENTITIES}`))
                  .filter(item => item !==`${APES_DATA_LOAD_TOOL_MAIN_BUCKET}/${WMS_BUCKET_ENTITIES}`)
                  .map((item) => (
                    <div key={item} className="file-item">
                      <a href={item} download className="download-link">
                        <span role="img" aria-label="Download Icon" className="download-icon">
                          📥
                        </span>{" "}
                        {getFileNameWithoutExtension(item, WMS_BUCKET_ENTITIES)}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="column-center">
                Carga de información
                <Dropzone onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles.slice(0,1), WMS_BUCKET_DESTINATION)}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>Arrastra y suelta archivos aquí, o haz clic para seleccionar archivos</p>
                    </div>
                  )}
                </Dropzone>
                {notification && (
                <p className={notification.type === 'success' ? 'success-message' : 'error-message'}>
                  {notification.message}
                </p> )}
              </div>
              <div className="column-right">
                Entidades validadas
                <div className="file-container">
                    {publicFiles
                      .filter(item => item.includes(`${APES_DATA_LOAD_TOOL_MAIN_BUCKET}/${WMS_BUCKET_DESTINATION}`))
                      .map((item) => (
                        <div key={item} className="file-item">
                          {item !== `${APES_DATA_LOAD_TOOL_MAIN_BUCKET}/${WMS_BUCKET_DESTINATION}` && (
                            <a href={item} download className="download-link">
                              <span role="img" aria-label="Download Icon" className="download-icon">
                                📥
                              </span>{" "}
                              {getFileNameWithoutExtension(item, WMS_BUCKET_DESTINATION)}
                            </a>
                          )}
                        </div>
                      ))}
                  </div>
              </div>
            </div>
          )}

          
        </div>
   
      </div>
    </Authenticator>
  );
}

export default App;
